import * as React from "react"
import { styled } from "../../../stitches.config";
import { request } from "https";

type PlayOnScrollProps = {
    blockData: {
        backgroundUrl: string;
        isPlayingFromTop: boolean;
        author?: {
            [key: string]: string;
        }
        category?: {
            show_category_title: boolean;
            [key: string]: string | boolean;
        }
    }
}

const PlayOnScroll: React.FC<PlayOnScrollProps> = (props) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const videoRef = React.useRef<HTMLVideoElement>(null);
    const rafRef = React.useRef<any>()
    const [loaded, setLoaded] = React.useState<boolean>(false)

    const handleScroll = () => {
        const { isPlayingFromTop } = props.blockData
        const { top, bottom, height } = ref.current?.getBoundingClientRect() as DOMRect;
        if (videoRef.current && loaded) {
            if (isPlayingFromTop) {
                if (bottom >= 0) {
                    // odpalany od góry strony
                    const progress = Math.round((Math.abs(top) / height * 1000))
                    const currTime = videoRef.current?.duration * progress / 1000
                    // console.log({ currTime, progress })
                    videoRef.current.currentTime = currTime
                }
            } else {
                if (top <= window.innerHeight && bottom >= 0) {
                    // odpalany od pojawienia się góry video w viewporcie do ukrycia się dołu
                    const progress = Math.round((1 - (bottom / (window.innerHeight + height))) * 1000)
                    const currTime = videoRef.current?.duration / 1000 * progress
                    // console.log({ currTime, progress })
                    videoRef.current!.currentTime = currTime
                }

            }
        }
        // console.log("floppa")
        // rafRef.current = requestAnimationFrame(handleScroll)
    }

    // const listenToScroll = React.useCallback(() => {
    //     requestAnimationFrame(() => handleScroll())
    // }, [handleScroll])

    React.useLayoutEffect(() => {
        videoRef.current?.pause();
        window.addEventListener("scroll", handleScroll)
        // rafRef.current = requestAnimationFrame(handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
            // cancelAnimationFrame(rafRef.current)
        }
    }, [handleScroll])

    return <Wrapper ref={ref}>
        <video ref={videoRef} muted loop playsInline autoPlay onLoadedData={() => setLoaded(true)}>
            <source src={props.blockData.backgroundUrl} type="video/mp4" />
        </video>
    </Wrapper>;
}

const Wrapper = styled("div", {
    width: "100 %",
    video: {
        margin: "0 auto",
        display: "block",
        'max-width': '100 %'
    }
});

export default PlayOnScroll