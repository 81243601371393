export default {
    home: "/",
    categoryTrudat: "/trudat/:paginatorPage?",
    categoryZdrowie: "/zdrowie/:paginatorPage?",
    zdrowieArticle: "/zdrowie/:slug/",
    category: "/c/:slug/:paginatorPage?",
    blogerArticle: "/blogi/:author/:slug",
    blogerHome: "/blogi/:author",
    blogs: "/blogs",
    article: "/:slug",
    articleQuiz: "/:slug/quiz/:quiz/:quizQuestion?",
    topic: "/t/:slug",
    user: "/u/:slug",
    jobOfferArticle: "/kariera/:slug/",
    fertilityCalcPage: "/kalkulator-dni-plodnych",
    info: "/info/:slug",
    login: "/login",
    register: "/register",
    resetPassword: "/resetPassword",
    resetPasswordToken: "/reset",
    dashboard: "/dashboard",
    adTests: "/adPage",
    contact: "/contact",
    career: "/kariera",
    authortip: "/tip",
    readertip: "/napiwki",
    search: "/search",
    privacy: "/prywatnosc",
    puzzle: "/puzzle",
    postsmapsubpage: "/posts-map/:id",
    postsmap: "/posts-map",
    redakcja: "/redakcja",
    forYou: "/dla-ciebie",
    podcasts: "/podcasty",
    podcastSerie: '/podcasty/:slug',
    exitScreen: '/exitscreen',
    testPlayground: '/test-playground',
}