import React, { useState, useEffect, Suspense, useRef } from "react"
import { useLocation } from "react-router-dom";

import { useStores } from "../../../../helpers";
import useMediaQuery from "../../../../helpers/useMediaQuery";

import NavPlaceholder from "./NavPlaceholder";


const NavbarSimple = React.lazy(() =>/* webpackChunkName: "NavbarSimple" */ import("../NavbarSimple"));
const NavbarFull = React.lazy(() =>/* webpackChunkName: "NavbarFull" */ import("../NavbarFull"));
const NavbarFixed = React.lazy(() =>/* webpackChunkName: "NavbarFixed" */ import("../NavbarFixed"));

/**
 * active:
 * SIMPLE - artykuł atavist
 * 
 * @deprecated:
 * AUDIO - player czytający artykuł 
 * HARDCOVER - hardcover
 */

const Nav: React.FC = () => {
    const { UIStore } = useStores();
    const navType = UIStore.nav;
    const isDesktop = useMediaQuery('bp4');

    const [showNavArticle, setShowNavArticle] = useState(false);
    const [articleProgress, setArticleProgress] = useState(0);
    const prevScrollHeight = useRef(0);

    useEffect(() => {
        const onScroll = () => {
            const scrollTop =
                window?.pageYOffset ||
                document?.documentElement?.scrollTop ||
                document?.body?.scrollTop ||
                0;

            const artContent = document.getElementById('article-body');
            if (artContent) {
                const articlePosition = artContent.getBoundingClientRect();
                const articleTop = window.innerHeight - articlePosition.top;
                const articleHeight = articlePosition.height;

                setShowNavArticle(/* scrollTop > 180 && articleTop > 0 && */ articleTop < articleHeight);
                setArticleProgress(Math.round(100 * articleTop / articleHeight));
            } else {
                setShowNavArticle(false);
                setArticleProgress(0)
            }

            // dodatkowa klasa body w zaleznosci od kierunku scrolla
            if (scrollTop <= 150) {
                document.body.classList.remove('scrolling-up');
                document.body.classList.remove('scrolling-down');
            } else if (scrollTop > prevScrollHeight.current) {
                document.body.classList.remove('scrolling-up');
                document.body.classList.add('scrolling-down');
            } else {
                document.body.classList.remove('scrolling-down');
                document.body.classList.add('scrolling-up');
                setShowNavArticle(false);
                setArticleProgress(0)
            }
            prevScrollHeight.current = scrollTop;
        }

        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return <Suspense fallback={<NavPlaceholder />}>
        {navType === "SIMPLE" ?
            <NavbarSimple />
            :
            <>
                <NavbarFixed showNavArticle={showNavArticle && !isDesktop} articleProgress={articleProgress} />
                <NavbarFull />
            </>
        }
    </Suspense>
}

export default Nav;